import React from "react";
import Button from "./global/Button";

type Props = {
    handle_submission: () => void;
    disabled?: boolean
}

const Create_Button = (props: Props) => {

    function handle_click() {
        props.handle_submission();
    }
    
    return (<>
        <div>
            <Button size={"large"} text={"Create your build!"} disabled={props.disabled ?? false} on_click={() => handle_click()}/>
        </div>
    </>)
}

export default Create_Button;